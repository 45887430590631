import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcSelectedChipCount'
})
export class CalcSelectedChipCountPipe implements PipeTransform {
  transform(array: any[]): number {
    if(!array) return 0
    return array.filter(el => el.selected).length
  }
}
