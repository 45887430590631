import { MbscModule } from '@mobiscroll/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { MatTreeModule } from '@angular/material/tree';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainModule } from './modules/main/main.module';

import { LayoutComponent } from './modules/main/layout/layout.component';
import { SidebarComponent } from './modules/main/layout/sidebar/sidebar.component';
import { InputComponent } from './modules/main/components/input/input.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RatePerMileFilterPipe } from './pipes/rate-per-mile-filter.pipe';
import { CalcSelectedChipCountPipe } from './pipes/calc-selected-chip-count.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SortMultiChipSelectPipe } from './pipes/sort-multi-chip-select.pipe';
import { AUTH_API_URL } from './services/app-injection-tokens';
import { environment } from '../environments/environment';
import { JwtInterceptor } from './jwt.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AlertComponent } from './components/alert/alert.component';
import { NotificationService } from './services/notification.service';
import { LoaderComponent } from './components/loader/loader.component';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';

const PIPES = [
  RatePerMileFilterPipe,
  CalcSelectedChipCountPipe,
  SortMultiChipSelectPipe,
];

const MATERIAL = [
  MatIconModule,
  MatTreeModule,
  MatButtonModule,
  MatFormField,
  MatAutocompleteModule,
  MatLabel,
  MatTooltipModule,
  MatProgressBarModule
]
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SidebarComponent,
    InputComponent,
    AlertComponent,
    LoaderComponent,
    [...PIPES]
  ],
  imports: [
    MbscModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTreeModule,
    MatButtonModule,
    MainModule,
    FormsModule,
    AppRoutingModule,
    MatSnackBarModule,
    HttpClientModule,
    [...MATERIAL],
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    NotificationService,
    {
      provide: AUTH_API_URL,
      useValue: environment.apiUrl
    },
    [CookieService],
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
