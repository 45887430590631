<div>
  <div *ngFor="let notification of notifications" (mouseenter)="onMouseEnter(notification)"
    (mouseleave)="onMouseLeave(notification)" (click)="close(notification)">
    <ng-container *ngTemplateOutlet="notificationTpl; context: {notification: notification}"></ng-container>
    <ng-template #notificationTpl let-notification="notification">
      <div [ngClass]="{'alert-container-open' : notification.isOpen, 'alert-container-close' : !notification.isOpen}">
        <div [ngClass]="className(notification)" class="alert-container">
          <div class="alert-icon">
            <img [src]="chengeIconByType(notification.type)" alt="icon">
          </div>
          <div class="alert-type-description">
            <div class="alert-type">{{ notification.type }}</div>
            <div class="alert-description">{{ notification.message }}</div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>