import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { Notification, NotificationType } from '../model/notification';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  notifications: Notification[] = [];
  private _subscription!: Subscription;
  constructor(
    private _notificationSvc: NotificationService,
  ) { }

  private _addNotification(notification: Notification) {
    notification.isOpen = true;
    this.notifications.push(notification);
    // Спросить если нужно закрывать через определенное время!!!
    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }

  ngOnInit() {
    this._subscription = this._notificationSvc.getObservable().subscribe(notification => this._addNotification(notification));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  close(notification: Notification) {
    notification.isOpen = false;
    setTimeout(() => this.notifications = this.notifications.filter(notif => notif.id !== notification.id), 1000)
  }


  className(notification: Notification): string {

    let style: string;

    switch (notification.type) {

      case NotificationType.success:
        style = 'success';
        break;

      case NotificationType.warning:
        style = 'warning';
        break;

      case NotificationType.error:
        style = 'error';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }

  chengeIconByType(type: string) {
    let src = '';
    switch (type) {
      case NotificationType.success:
        src = '../../../assets/svg/CheckCircleOutlined.svg';
        break;

      case NotificationType.warning:
        src = '../../../assets/svg/WarningAmberOutlined.svg';
        break;

      case NotificationType.error:
        src = '../../../assets/svg/ErrorOutline.svg';
        break;

      default:
        src = '../../../assets/svg/InfoOutlined.svg';
        break;
    }
    return src;
  }


  onMouseEnter(notification: Notification) {
    if (notification['timeout']) {
      clearTimeout(notification['timeout']);
    }
  }

  onMouseLeave(notification: Notification) {
    if (notification.timeout !== 0) {
      notification['timeout'] = setTimeout(() => this.close(notification), notification.timeout);
    }
  }

}
