import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ratePerMileFilter'
})
export class RatePerMileFilterPipe implements PipeTransform {

  transform(array: any[], key: {type:string,key:string}): any {

    let codes = {
      broker: {name: 'BusinessName',},
      driver: {name: 'Name'},
      status: {name: 'name'},
      dispatcher: {name: 'Name'},
      carrier: {name: 'Name'},
      vehicle: {name: 'VehicleUnit', options: 'Make',}
    }

    if (!key.key) return array
    if (!array.length) return []

    return array.filter(el => el[codes[key.type as keyof typeof codes].name].toLowerCase().includes(key.key.toLowerCase()));
  }
}
