import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortMultiChipSelect'
})
export class SortMultiChipSelectPipe implements PipeTransform {

  transform(arr: any[]): any[] {
    return arr.sort((a, b) => {
      if (a.selected && !b.selected) {
        return -1;
      } else if (!a.selected && b.selected) {
        return 1;
      } else {
        return a.Name.localeCompare(b.Name);
      }
    });
  }
}
